/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  Table,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  CardFooter,
  CardTitle,
  Row
} from "reactstrap";


import 'sweetalert2/src/sweetalert2.scss';
import 'views/DHBLayoutSideBar.css'

import UserService from '../services/user.service.v3';
import UserDetails from "../components/Childrens/UserDetails.v3"
import UserCreate from "../components/Childrens/UserCreate.v3"

import UserDetailsOld from "../components/Childrens/DHBUserDetails"

import Select from 'react-select';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

class Tables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listEmployee: [],
      page: "",
      dataProps: {},
      language: "Vietnamese",
      calendar: new Date(),
      activeCalendar: false,
      expired: "",
      search: null,
      showloai: false,
      showlang: false,
      counttong: 0,
      counthd: 0,
      counthuy: 0,

      indexItem: 0,
      showLoadTruoc: false,
      showLoadSau: false,
    }
  }

  componentDidMount() {
    this.loadDataTable()
  }

  loadDataTable() {
    UserService.getAllUser(this.getTool(), this.state.indexItem, this.state.search)
      .then(response => {

        console.log(response.data)

        var loai = false
        var lang = false
        var emp = []

        response.data.user.forEach(item => {
          if (item.email == null) item.email = item.username + "@gmail.com"
          if (item.version == "Thường" || item.version == "Pro") loai = true
          if (item.language == "Vietnamese" || item.language == "English") lang = true

          emp.push(item)
        });

        this.setState({ listEmployee: emp, showloai: loai, showlang: lang });
      }, error => {
        window.location = "/"
        alert("Lỗi, đổ dữ liệu : " + error)
      }

      ).catch(error => {
        window.location = "/"
        alert("Catch đổ dữ liệu : " + error)
      })
  }

  getTool() {
    const queryPath = window.location.pathname;
    return queryPath.replace(/admin/gi, '').replace(/[^a-zA-Z0-9 ]/g, '')
  }

  back() {
    return (

      <Button
        color="info"
        onClick={() => this.setState({ page: "" })}>
        &laquo; Quay lại
      </Button>

    )
  }

  changePage = (newPage, type, user) => {
    this.setState({ page: newPage });
    if (type == "add") {

      console.log(user)

      this.setState(prevState => ({
        listEmployee: [user, ...prevState.listEmployee]
      }));
    } else if (type == "edit") {
      this.setState(prevState => ({
        listEmployee: prevState.listEmployee.map(employee =>
          employee.id === user.id ? user : employee
        )
      }));
    } else if (type == "delete") {
      this.setState(prevState => ({
        listEmployee: prevState.listEmployee.filter(employee => employee.id !== user)
      }));
    }
  };

  optionsLanguage = () => {
    return [
      { value: 'Vietnamese', label: 'Vietnamese' },
      { value: 'English', label: 'English' },
    ]
  }

  formatDate(val) {
    const date = new Date(val)
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }


  searchSpace = (e) => {
    if (e.key == 'Enter') {
      let keyword = e.target.value;
      this.setState({ search: keyword })
      this.loadDataTable(keyword)
    }
  }

  render() {

    return (
      <>
        <div className="content">
          {
            this.state.page == 'details'
              ?
              <>
                {this.back()}
                <UserDetails path={this.getTool()} data={this.state.dataProps} changePage={this.changePage} />
              </>
              :
              this.state.page == 'detailsOld'
                ?
                <>
                  {this.back()}
                  <UserDetailsOld path={this.getTool()} data={this.state.dataProps} changePage={this.changePage} />
                </>
                :
                this.state.page == 'create'
                  ?
                  <>
                    {this.back()}
                    <UserCreate path={this.getTool()} changePage={this.changePage} />
                  </>
                  :
                  <>
                    <div className="card">

                      <div className="card-header">
                        <div className="function justify-content-between">
                          <button
                            className="btn btn-primary col-sm-2"
                            onClick={() => this.setState({ page: "create" })}>
                            Thêm tài khoản
                          </button>

                          <Input
                            className="input-filter col col-lg-3"
                            value={this.state.search}

                            onChange={(e) => {
                              let keyword = e.target.value;
                              this.setState({ search: keyword })

                            }}

                            onKeyDown={(e) => {
                              if (e.key == 'Enter') {
                                this.loadDataTable()
                              }
                            }}

                            placeholder="Tìm kiếm"
                          />
                        </div>

                        {
                          this.state.activeCalendar
                            ?
                            <>
                              <Calendar
                                onChange={(e) => this.setState({ calendar: e })}
                                value={this.state.calendar}
                              />
                              <button onClick={() => this.setState({ expired: this.formatDate(this.state.calendar), activeCalendar: false })} className="btn btn-primary">Hoàn tất</button>
                              <button onClick={() => this.setState({ activeCalendar: false })} className="btn btn-primary">Hủy</button>
                            </>
                            :
                            null
                        }
                      </div>
                      <div className="card-body">
                        {this.table()}
                      </div>

                      <div className="card-footer">
                        <hr />
                        <nav className="pagination justify-content-center ">
                          <ul className="pagination">
                            <Button
                              style={{ marginRight: "10px" }}
                              color="info"
                              onClick={() => {
                                var page = this.state.indexItem - 20
                                if (page < 0) {
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Đây là trang đầu',
                                    showConfirmButton: false,
                                    timer: 1000
                                  })
                                  return
                                }

                                this.setState({ showLoadTruoc: true, indexItem: page })

                                UserService.getAllUser(this.getTool(), page).then((response) => {

                                  var emp = []
                                  response.data.user.forEach(item => {
                                    emp.push(item)
                                  });

                                  this.setState({ listEmployee: emp, showLoadTruoc: false });
                                })
                              }}>
                              {this.state.showLoadTruoc ? <span class="spinner-border spinner-border-sm"></span> : <> &laquo; Trang trước </>}

                            </Button>
                            <Button
                              color="info"
                              onClick={() => {
                                var page = this.state.indexItem + 20
                                this.setState({ showLoadSau: true, indexItem: page })

                                UserService.getAllUser(this.getTool(), page).then((response) => {

                                  var emp = []
                                  response.data.user.forEach(item => {
                                    emp.push(item)
                                  });

                                  this.setState({ listEmployee: emp, showLoadSau: false });
                                })
                              }}>
                              {this.state.showLoadSau ? <span class="spinner-border spinner-border-sm"></span> : <> Trang sau &raquo; </>}
                            </Button>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </>
          }

        </div>
      </>
    );
  }

  table() {
    return (
      <>
        <Table className="table-hover" style={{ lineHeight: "15px" }}>
          <thead className="text-primary">
            <tr>
              <th className="table-device-mobile">ID</th>
              <th>Tài khoản</th>
              <th className="table-device-mobile">Email</th>
              <th>Họ tên</th>
              <th className="table-device-mobile table-device-tablet">Máy</th>
              <th className="table-device-mobile table-device-tablet table-device-large-size-tablet">Đếm</th>
              <th className="table-device-mobile table-device-tablet table-device-large-size-tablet">P. Bản</th>

              {
                this.state.showloai ?
                  <th className="table-device-mobile table-device-tablet table-device-large-size-tablet">Loại</th>
                  :
                  null
              }

              {
                this.state.showlang ?
                  <th className="table-device-mobile table-device-tablet table-device-large-size-tablet">Ngôn ngữ</th>
                  :
                  null
              }

              <th className="table-device-mobile table-device-tablet table-device-large-size-tablet">Lần cuối</th>
              <th className="table-device-mobile table-device-tablet">Hết hạn</th>
            </tr>
          </thead>
          <tbody>
            {this.loadFillData()}
          </tbody>
        </Table>
      </>
    )
  }

  loadFillData() {

	
    return this.state.listEmployee.map((data, key) => {

      var detailType = "detailsOld"
      var countDevice

      try {
        countDevice = data.drive.split(",").length
      } catch (error) {
      }
      try {
			console.log(data.versionapi)
        if (data.versionapi == 3) {
          detailType = "details"
          countDevice = data.drive.length
        }
      } catch (error) {
      }
      

      return (
        <tr className={data.color} style={{ cursor: "pointer" }} key={key} onClick={() => { this.setState({ page: detailType, dataProps: data }) }}>
          <th className="table-device-mobile">{data.id}</th>
          <td>{data.username}</td>
          <td className="table-device-mobile">{data.email}</td>
          <td>{data.fullname}</td>
          <td className="table-device-mobile table-device-tablet">{countDevice}</td>
          <td className="table-device-mobile table-device-tablet table-device-large-size-tablet">{data.countlogin}</td>
          <td className="table-device-mobile table-device-tablet table-device-large-size-tablet">{data.versiontool}</td>

          {
            this.state.showloai ?
              <td className="table-device-mobile table-device-tablet table-device-large-size-tablet">{data.version}</td>
              :
              null
          }

          {
            this.state.showlang ?
              <td className="table-device-mobile table-device-tablet table-device-large-size-tablet">{data.language}</td>
              :
              null
          }

          <td className="table-device-mobile table-device-tablet">{moment(data.loginold).format('DD/MM/yyyy')}</td>
          <td className="table-device-mobile table-device-tablet">{moment(data.expired).format('DD/MM/yyyy')}</td>
        </tr >
      )
    })
  };
}

export default Tables;
