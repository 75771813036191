import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.dhbtools.com/admin/v3/";

class OrderService {

  getOrder(page) {
    return axios.get(API_URL + "orders?page=" + page, { headers: authHeader() });
  }

}

export default new OrderService();
